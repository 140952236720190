<template>
    <scroll-container>
        <div class="skins">
            <slot />
        </div>
    </scroll-container>
</template>

<script>
    import ScrollContainer from '@/components/ScrollContainer';

    export default {
        name: 'Skins',
        components: {
            ScrollContainer,
        },
    };
</script>

<style lang="scss" scoped>
    .skins {
        display: grid;
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    }

    @media screen and (min-width: 120em) {
        .skins {
            grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        }
    }
</style>
