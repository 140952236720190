<template>
    <p class="hint" v-html="message" />
</template>

<script>
    export default {
        name: 'Hint',
        props: {
            message: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .hint {
        width: max-content;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: rgba(104, 118, 151, 0.6);
        backdrop-filter: blur(3px);
        color: #ccd1de;
        font-family: 'Gotham Medium', sans-serif;
        font-size: 1.1rem;
        font-weight: 350;
        line-height: 1.2;
        text-align: center;
    }
</style>
