<template>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.84726 4.86949C5.57726 4.59949 5.1395 4.59949 4.86949 4.86949C4.59949 5.1395 4.59949 5.57726 4.86949 5.84726L7.02229 8.00005L4.86949 10.1528C4.59949 10.4229 4.59949 10.8606 4.86949 11.1306C5.1395 11.4006 5.57726 11.4006 5.84726 11.1306L8.00005 8.97782L10.1528 11.1306C10.4229 11.4006 10.8606 11.4006 11.1306 11.1306C11.4006 10.8606 11.4006 10.4229 11.1306 10.1528L8.97782 8.00005L11.1306 5.84726C11.4006 5.57726 11.4006 5.1395 11.1306 4.86949C10.8606 4.59949 10.4229 4.59949 10.1528 4.86949L8.00005 7.02229L5.84726 4.86949Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'UnionIcon',
    };
</script>
