<template>
    <div class="filter-actions">
        <checkbox
            :value="selectAll"
            :label="$t('pay.deposit.filters.select')"
            :disabled="disabled || selectAllDisabled"
            size="small"
            @change="$emit('selectAll', !selectAll)"
        />

        <icon-button theme="secondary" size="small" @click="$emit('reset')" :disabled="disabled">
            <retry-arrow-icon />
        </icon-button>
    </div>
</template>

<script>
    import Checkbox from '@/components/inputs/Checkbox';
    import IconButton from '@/components/buttons/IconButton';
    import RetryArrowIcon from '@/components/icons/RetryArrowIcon';

    export default {
        name: 'FilterActions',
        components: {
            Checkbox,
            IconButton,
            RetryArrowIcon,
        },
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            selectAll: {
                type: Boolean,
                default: false,
            },
            selectAllDisabled: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter-actions {
        display: flex;
        grid-gap: 0.5rem;

        ::v-deep {
            .checkbox,
            .icon-button {
                background-color: #151b2b;
                color: #545e77;

                svg {
                    fill: #545e77;
                }

                &:hover {
                    background-color: #191f2e;
                    color: #a9aebd;

                    svg {
                        fill: #a9aebd;
                    }
                }
            }

            .checkbox {
                input {
                    &:not(:checked) {
                        background-color: #1e273a;
                    }
                }

                span {
                    display: none;
                }

                &_checked {
                    background-color: #191f2e;
                    color: #a9aebd;
                }
            }
        }
    }

    @media screen and (min-width: 26em) {
        .filter-actions::v-deep .checkbox span {
            display: revert;
        }
    }

    @media screen and (min-width: 120em) {
        .filter-actions {
            grid-gap: 1rem;
        }
    }
</style>
