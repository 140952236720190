<template>
    <div :class="['icon-with-hint', `icon-with-hint_${position}`]">
        <info-icon class="icon-with-hint__icon" />

        <div class="icon-with-hint__content">
            <hint class="icon-with-hint__hint" :message="message" />

            <div class="icon-with-hint__arrow" />
        </div>
    </div>
</template>

<script>
    import Hint from '@/components/Hint';
    import InfoIcon from '@/components/icons/InfoIcon';

    export default {
        name: 'IconWithHint',
        components: {
            Hint,
            InfoIcon,
        },
        props: {
            message: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                default: 'left',
                validator: value => ['center', 'left', 'right'].includes(value),
            },
        },
    };
</script>

<style lang="scss" scoped>
    .icon-with-hint {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &_center &__hint {
            left: 50%;
            transform: translate(-50%, 0);
        }

        &_left &__hint {
            left: -1rem;
        }

        &_right &__hint {
            right: -1rem;
        }
    }

    .icon-with-hint__arrow {
        position: absolute;
        left: 50%;
        bottom: 100%;
        border: 0.5rem solid;
        border-color: rgba(104, 118, 151, 0.6) transparent transparent transparent;
        transform: translate(-50%, 0);
        z-index: 999;
    }

    .icon-with-hint__content {
        display: none;
    }

    .icon-with-hint__hint {
        position: absolute;
        bottom: 100%;
        margin-bottom: 1rem;
        z-index: 999;
    }

    .icon-with-hint__icon {
        width: 1.6rem;
        height: 1.6rem;
        cursor: pointer;
        fill: #3f465a;
        transition: fill 0.3s;

        &:hover {
            fill: #4b5975;

            & + .icon-with-hint__content {
                display: block;
            }
        }
    }
</style>
