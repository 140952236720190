<template>
    <div :class="['alert', `alert_${theme}`]">
        <icon-with-hint v-if="hint" :message="hint" />

        <p class="alert__message" v-html="text" />
    </div>
</template>

<script>
    import { THEME_COLORS } from '@/constants/themeColors';
    import IconWithHint from '@/components/IconWithHint';
    import InfoCircleIcon from '@/components/icons/InfoCircleIcon';

    export default {
        name: 'AlertComponent',
        components: {
            IconWithHint,
            InfoCircleIcon,
        },
        props: {
            hint: String,
            text: {
                type: String,
                required: true,
            },
            theme: {
                type: String,
                default: 'primary',
                validator: value => THEME_COLORS.includes(value),
            },
        },
    };
</script>

<style lang="scss" scoped>
    .alert {
        display: flex;
        align-items: center;
        grid-gap: 1rem;
        padding: 1.6rem 1.7rem;
        border-radius: 1rem;
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.2rem;
        font-weight: 325;
        line-height: 1.3;

        &_warning {
            color: #ffd79a;
            background-color: #313236;
        }
    }

    .alert__message::v-deep strong {
        font-family: 'Gotham Bold', sans-serif;
        font-weight: 400;
    }

    ::v-deep {
        .icon-with-hint__icon {
            width: 2rem;
            height: 2rem;

            path {
                fill: #fabf66;
            }
        }

        .icon-with-hint__hint {
            max-width: 28.5rem;
        }
    }

    @media screen and (min-width: 75em) {
        .alert {
            padding: 2rem;
            font-size: 1.4rem;
        }
    }
</style>
