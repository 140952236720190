<template>
    <div :class="{ 'filter-inputs': true, 'filter-inputs_disabled': disabled }">
        <input-component
            :value="value.name"
            :placeholder="$t('pay.deposit.filters.name')"
            :hint="$t('projects.settings.form.deposit_multiplier.hint')"
            size="small"
            theme="secondary"
            outlined
            @input="$emit('input', { ...value, name: $event })"
        >
            <template #innerPrefix>
                <search-icon />
            </template>

            <template #innerSuffix>
                <union-icon v-if="value.name" @click.native="$emit('input', { ...value, name: '' })" />
            </template>
        </input-component>

        <inline-select
            :options="appOptions"
            :selected="indexById(value.appId)"
            size="small"
            @input="$emit('input', $event >= 0 ? { ...value, appId: apps[$event].id } : value)"
            :show-label="false"
        />

        <text-button
            :class="{ 'filter-inputs__price_asc': value.order }"
            size="small"
            theme="secondary"
            @click="$emit('input', { ...value, order: !value.order })"
        >
            {{ $t('pay.deposit.filters.price') }}
            <arrow-down-icon />
        </text-button>
    </div>
</template>

<script>
    import ArrowDownIcon from '@/components/icons/ArrowDownIcon';
    import IconButton from '@/components/buttons/IconButton';
    import InlineSelect from '@/components/inputs/InlineSelect';
    import InputComponent from '@/components/inputs/InputComponent';
    import SearchIcon from '@/components/icons/SearchIcon';
    import TextButton from '@/components/buttons/TextButton';
    import UnionIcon from '@/components/icons/UnionIcon';

    export default {
        name: 'FilterInputs',
        components: {
            ArrowDownIcon,
            IconButton,
            InlineSelect,
            InputComponent,
            SearchIcon,
            TextButton,
            UnionIcon,
        },
        props: {
            apps: {
                type: Array,
                default: () => [],
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Object,
                required: true,
            },
        },
        computed: {
            appOptions() {
                return this.apps ? this.apps.map(app => ({ label: app.name, image: app.icon })) : [];
            },
        },
        methods: {
            indexById(id) {
                return this.apps.findIndex(app => app.id.toString() === id.toString());
            },
        },
    };
</script>

<style lang="scss" scoped>
    .filter-inputs {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 0.5rem;

        ::v-deep {
            .inline-select-option {
                background-color: #151b2b;

                &_selected {
                    background-color: #191f2e;
                }
            }

            .input {
                flex: 1 1 23rem;
                order: 1;

                &__inner-prefix svg {
                    fill: #545e77;
                }

                &__inner-suffix svg {
                    fill: #a9aebd;
                    cursor: pointer;
                }

                &_outlined input {
                    border-color: #151b2b;
                    color: #a9aebd;

                    &::placeholder {
                        color: #545e77;
                    }

                    &:focus {
                        background-color: #191f2e;
                    }
                }

                &:has(input:focus, input:valid:not(:placeholder-shown)) {
                    .input {
                        &__inner-prefix svg {
                            fill: #a9aebd;
                        }

                        &__input {
                            border-color: #191f2e;
                        }
                    }
                }
            }

            .text-button {
                background-color: #191f2e;
                color: #a9aebd;

                svg {
                    fill: #a9aebd;
                }
            }
        }

        &_disabled {
            pointer-events: none;
        }
    }

    .filter-inputs__price_asc svg {
        transform: rotate(180deg);
    }

    @media screen and (min-width: 26em) {
        .filter-inputs {
            flex-wrap: nowrap;

            > .input {
                order: unset;
            }
        }
    }

    @media screen and (min-width: 64em) {
        .filter-inputs {
            justify-content: end;

            > .input {
                flex: 0 0 23rem;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .filter-inputs {
            grid-gap: 1rem;
        }
    }
</style>
